<template>
  <div>
    <v-alert
      v-if="!permissionCheck('view-device-history')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="filteredPurchaseHistory"
            :search="search"
            class="elevation-1 clickable"
            mobile-breakpoint="100"
            disable-sort
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.created_at | convertStringToLocalDatetime }}</td>
                <td>{{ item.type | capitalizeFirstLetter }}</td>
                <td>
                  <font
                    v-if="item.registered_at !== null"
                    class="font-my-style"
                    color="#2E7D32"
                  >
                    Used
                  </font>
                  <font
                    v-else
                    class="font-my-style"
                    color="#C62828"
                  >
                    Not Used
                  </font>
                </td>
                <td>{{ item.start | convertStringToLocalDatetime }}</td>
                <td>{{ item.end | convertStringToLocalDatetime }}</td>
                <td>
                  <font
                    :color="getColor(item.status)"
                    class="font-my-style"
                  >
                    {{ item.status | capitalizeFirstLetter }}
                  </font>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <div class="py-3" />
      <centre-spinner :loading="loading" />
      <v-alert
        v-if="!permissionCheck('view-device-history')"
        text
        prominent
        type="warning"
        color="red"
        border="left"
        class="authorization-alert"
      >
        You are not authorized to perform this action. Please contact your administrator.
      </v-alert>
    </v-card>
  </div>
</template>
<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import moment from 'moment';
import global from 'src/mixins/global';
export default {
  name: 'DevicePurchaseList',
  components: {
    'centre-spinner': spinner,
  },
  filters: {
    convertStringToLocalDatetime (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    statusUpdate (status) {
      if (status !== null) {
        return 'Used';
      }
      return 'Not Used';
    },
  },
  mixins: [global],
  data () {
    return {
      loading: false,
      search: '',
      headers: [
        { text: 'Purchased On', value: 'created_at' },
        { text: 'Plan', align: 'start', value: 'type' },
        { text: 'Register Status', value: 'registered_at' },
        { text: 'Start Date', value: 'start' },
        { text: 'End date', value: 'end' },
        { text: 'Licence Status', value: 'status' },
      ],
    };
  },
  computed: {
    getPurchaseHistory () {
      return this.$store.getters['payments/getDigiBoardPurchaseHistory'];
    },
    filteredPurchaseHistory () {
      const data = this.getPurchaseHistory.filter(element => element !== null);
      const sortedList = data.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        return sortedList;
    },
  },
  async mounted () {
    this.permissionCheck('view-device-history') && await this.fetchPurchaseHistory();
  },
  methods: {
    async fetchPurchaseHistory () {
      this.loading = true;
      await this.$store.dispatch('payments/fetchDigiBoardPurchaseHistory');
      this.loading = false;
    },
    getColor (status) {
      if (status === 'active') {
        return '#2E7D32';
      } else if (status === 'expired') {
        return '#C62828';
      } else {
        return '#EF6C00';
      }
    },
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.font-my-style {
  font-weight: bold;
}
.v-data-table {
  background-color: #ECEFF1;
}
.my-toolbar-style {
  color: #37474F;
  font-weight: bold;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
.my-media-file {
  background-color: rgb(236, 239, 241);
}
.my-card-size {
  height: 120px;
  padding-left: 5px;
  padding-right: 5px;
}
.my-row-style {
  margin-top: 5px;
}
</style>
