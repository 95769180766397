<template>
  <div
    id="regular-tables"
    fluid
    tag="section"
    style="margin-left: 21px;"
  >
    <br>
    <h2 class="title-style pl-3">
      <v-icon class="icon-style">
        mdi-wallet
      </v-icon> Purchase List
    </h2>
    <br>
    <v-tabs
      v-model="tab"
      color="#FF3700"
      background-color="#ECEFF1"
      style="margin-left: 20px;"
    >
      <v-tab
        v-for="item in items"
        :key="item.tab"
      >
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      class="my-tabs-background-style"
      touchless
    >
      <v-tab-item
        v-for="item in items"
        :key="item.tab"
      >
        <v-card-text>
          <component :is="item.content" />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
  <script>
    import DevicePurchaseList from './DevicePurchaseList.vue';
    import MediaPlayerPurchaseList from './MediaPlayerPurchaseList.vue';
    export default {
      name: 'UserPurchaseList',
      components: {
        'device-purchase-list': DevicePurchaseList,
        'media-player-purchase-list': MediaPlayerPurchaseList,
      },
      data: () => ({
        tab: null,
        items: [
        { tab: 'Devices', content: DevicePurchaseList },
        { tab: 'Media Players', content: MediaPlayerPurchaseList },
        ],
      }),
    };
  </script>
  <style scoped>
  .title-style {
    color: #37474F;
  }
  .icon-style {
    color: #37474F;
    font-size: 45px;
  }
  .my-tabs-background-style {
      background-color: #ECEFF1 !important;
  }
  </style>
