<template>
  <div>
    <v-alert
      v-if="!permissionCheck('view-device-history')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-row>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="[]"
            :search="search"
            class="elevation-1 clickable"
            mobile-breakpoint="100"
          />
        </v-col>
      </v-row>
      <div class="py-3" />
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </div>
</template>
  <script>
    import spinner from 'src/views/dashboard/component/SpinnerCentre';
    import global from 'src/mixins/global';
    export default {
      name: 'MediaPlayerPurchaseList',
      components: {
        'centre-spinner': spinner,
      },
      mixins: [global],
      data () {
        return {
          loading: false,
          search: '',
          headers: [
            { text: 'Plan', align: 'start', value: 'type' },
            { text: 'Licence Key', value: 'key' },
            { text: 'Purchase Date', value: 'start' },
            { text: 'Expired At', value: 'end' },
            { text: 'Status', value: 'active' },
            { text: 'Last Updated', value: 'updated_at' },
            ],
        };
      },
    };
  </script>
  <style scoped>
  .clickable {
    cursor: pointer;
  }
  .v-data-table::v-deep th {
    font-size: 12px !important;
    color: #37474F !important;
    font-weight: bold !important;
  }
  .v-data-table::v-deep td {
    font-size: 13px !important;
    color: #37474F !important;
  }
  .theme--light.v-data-table tbody tr:nth-of-type(even) {
    background-color: #CFD8DC
  }
  .v-data-table {
    background-color: #ECEFF1;
  }
  .my-toolbar-style {
    color: #37474F;
    font-weight: bold;
  }
  .authorization-alert {
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
  }
  </style>
