<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getProfileDetails()"
          >
            <v-list-item-icon>
              <v-icon class="icon-style">
                mdi-account
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="title-style"
            >
              My Profile
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getOrganizationDetails()"
          >
            <v-list-item-icon>
              <v-icon class="icon-style">
                mdi-domain
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="title-style"
            >
              Organization
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getProductPaymentPlans()"
          >
            <v-list-item-icon>
              <v-icon class="icon-style">
                mdi-credit-card-check
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="title-style"
            >
              Pricings
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getPaymentsHistory()"
          >
            <v-list-item-icon>
              <v-icon class="icon-style">
                mdi-clipboard-text-clock
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="title-style"
            >
              Payments
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getPurchaseHistory()"
          >
            <v-list-item-icon>
              <v-icon class="icon-style">
                mdi-wallet
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="title-style"
            >
              Purchases
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  class="icon-style"
                >
                  mdi-account-cog
                </v-icon>
              </v-list-item-icon>
              Profile
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getProfileDetails()"
          >
            <v-list-item-icon>
              <v-icon class="icon-style">
                mdi-account
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="title-style"
            >
              My Profile
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getOrganizationDetails()"
          >
            <v-list-item-icon>
              <v-icon class="icon-style">
                mdi-domain
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="title-style"
            >
              Organization
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getProductPaymentPlans()"
          >
            <v-list-item-icon>
              <v-icon class="icon-style">
                mdi-credit-card-check
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="title-style"
            >
              Pricings
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getPaymentsHistory()"
          >
            <v-list-item-icon>
              <v-icon class="icon-style">
                mdi-clipboard-text-clock
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="title-style"
            >
              Payments
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getPurchaseHistory()"
          >
            <v-list-item-icon>
              <v-icon class="icon-style">
                mdi-wallet
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="title-style"
            >
              Purchases
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <div>
      <user-profile v-if="showProfileDetails" />
      <product-payment-plans v-if="showProductPaymentPlans" />
      <organization-details v-if="showOrganizationDetails" />
      <subscriptions-history v-if="showPaymentsHistory" />
      <user-purchase-list v-if="showUserPurchaseList" />
    </div>
  </v-container>
</template>
<script>
import global from 'src/mixins/global';
import UserProfile from './UserProfile.vue';
import ProductPaymentPlans from './payments/ProductPaymentPlans.vue';
import Constants from 'src/constants';
import OrganizationDetails from './organization/OrganizationDetails.vue';
import SubscriptionsHistory from './payments/SubscriptionsHistory.vue';
import UserPurchaseList from './purchases/UserPurchaseList.vue';

 export default {
    name: 'ProfileSettings',
    components: {
      'user-profile': UserProfile,
      'product-payment-plans': ProductPaymentPlans,
      'organization-details': OrganizationDetails,
      'subscriptions-history': SubscriptionsHistory,
      'user-purchase-list': UserPurchaseList,
    },
    mixins: [global],
    data: () => ({
      selectedItem: -1,
      showProfileDetails: true,
      showProductPaymentPlans: false,
      showOrganizationDetails: false,
      showPaymentsHistory: false,
      showUserPurchaseList: false,
    }),
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
    },
    mounted () {
      this.selectedItem = 0;
    },
    methods: {
      getProfileDetails () {
        this.showProductPaymentPlans = false;
        this.showOrganizationDetails = false;
        this.showPaymentsHistory = false;
        this.showUserPurchaseList = false;
        this.showProfileDetails = true;
      },
      getProductPaymentPlans () {
        if (this.permissionCheck('view-product-by-type') === false || this.permissionCheck('read-subscriptions') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showProfileDetails = false;
        this.showOrganizationDetails = false;
        this.showPaymentsHistory = false;
        this.showUserPurchaseList = false;
        this.showProductPaymentPlans = true;
      },
      getOrganizationDetails () {
        if (this.permissionCheck('get-organization-details') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showPaymentsHistory = false;
        this.showProfileDetails = false;
        this.showProductPaymentPlans = false;
        this.showUserPurchaseList = false;
        this.showOrganizationDetails = true;
      },
      getPaymentsHistory () {
        if (this.permissionCheck('read-subscriptions') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showOrganizationDetails = false;
        this.showProfileDetails = false;
        this.showProductPaymentPlans = false;
        this.showUserPurchaseList = false;
        this.showPaymentsHistory = true;
      },
      getPurchaseHistory () {
        this.showOrganizationDetails = false;
        this.showProfileDetails = false;
        this.showProductPaymentPlans = false;
        this.showPaymentsHistory = false;
        this.showUserPurchaseList = true;
      },
    },
  };
</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.icon-style{
  font-size:25px;
  color:#37474F;
  font-weight:bold;
}
.title-style{
  font-size:14px;
  font-family: Roboto, sans-serif;
  color:#37474F;
  font-weight: bold!important;
}
</style>
